<template>
  <NavQuestion
    :heading="heading"
    :number="'1'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch :checked="details.pets_yes_no" @toggle="save"></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/home'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'PetsPrimaryOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    isValidated() {
      return true
    },
    heading() {
      return 'Would you like to name people to look after your pets?'
    },
    subHeading() {
      return (
        'Adding provision for the family goldfish could be considered unnecessary, ' +
        'but it may be worth making provisions in your Will for cats, dogs and other common pets.'
      )
    },
    forwardTo() {
      if (this.details.pets_yes_no && this.partner) return '/pets/partner'
      else if (this.details.pets_yes_no && !this.partner) return '/pets/primary'
      return '/pets/check_and_confirm'
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          pets_yes_no: !this.details.pets_yes_no
        })
        if (this.details.pets_yes_no === false) {
          this.resetPetsPrimary()
          this.resetPetsCash()
        }
      }
    },
    resetPetsPrimary() {
      this.people.forEach((person) => {
        person.pets_primary = false
        person.pets_sole_primary = false
        this.$store.commit('personAdd', person)
      })
    },
    resetPetsCash() {
      this.$store.commit('details', {
        amount: 0
      })
    }
  }
}
</script>

<style></style>
